<template>
  <div>
    <div class="vx-row mb-3">
      <div class="vx-col lg:w-9/12 w-full">
        <vx-card :is-refresh="isLoadingInitData">
          <vs-tabs alignment="fixed" v-model="activeTab">
            <vs-tab label="SPP Header" icon-pack="feather">
              <TabHeader :isActive="activeTab === 0" :initData="initData"/>
            </vs-tab>
            <vs-tab label="Detail Pekerjaan" icon-pack="feather">
              <TabPekerjaan :isActive="activeTab === 1" :initData="initData"/>
            </vs-tab>
            <vs-tab label="Termin Pembayaran" icon-pack="feather">
              <TabPembayaran :isActive="activeTab === 2" :initData="initData"/>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>

      <div class="vx-col lg:w-3/12 w-full mt-3 lg:mt-0">
        <vx-card :is-refresh="isLoadingInitData">
          <div class="flex items-center mb-base">
            <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
            <h5 class="font-medium">Approval Progress</h5>
          </div>
          <ApprovalProgress
            v-if="initData.header.id"
            ref="approvalProgress"
            :type="getApprovalType(initData.header.jenis_spk)"
            :id-ref="$route.params.idSpk"
            :isShowSigningAction="true"
            @signingClicked="onClickSigningAction"/>
        </vx-card>
      </div>
    </div>

    <!--modals-->
    <SpkKontrakApprovalSigning :isActive.sync="modalSigning.active"
                       :idSpk="modalSigning.idSpk"
                       :status="modalSigning.status"
                       @success="onSigned"/>
  </div>
</template>

<script>
import moduleSpkKontrakApproval from '@/store/modules/approvals/spk-kontrak-approval/spk-kontrak-approval.store'
import SpkRepository from '@/repositories/proyek/spk-repository'
import { getSpkApprovalType } from '@/constant/spk-approval-type'

export default {
  name: 'SpkKontrakApprovalShow',
  components: {
    SpkKontrakApprovalSigning: () => import('@/views/pages/approvals/spk-kontrak-approval/SpkKontrakApprovalSigning'),
    TabHeader: () => import('@/views/pages/approvals/spk-kontrak-approval/parts/TabHeader'),
    TabPekerjaan: () => import('@/views/pages/approvals/spk-kontrak-approval/parts/TabPekerjaan'),
    TabPembayaran: () => import('@/views/pages/approvals/spk-kontrak-approval/parts/TabPembayaran'),
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoadingInitData: false,
      initData: {
        header: {},
        pekerjaan: [],
        pembayaran: []
      },
      modalSigning: {
        active: false,
        idSpk: this.$route.params.idSpk,
        status: null
      }
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.approvals.spkKontrakApproval.activeTab
      },
      set (value) {
        this.$store.commit('approvals/spkKontrakApproval/SET_ACTIVE_TAB', value)
      }
    }
  },
  methods: {
    onSigned () {
      this.getInitData()
      this.$refs.approvalProgress.refresh()
    },

    getInitData () {
      this.isLoadingInitData = true

      const idSpk = this.$route.params.idSpk
      SpkRepository.show(idSpk)
        .then(response => {
          this.initData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    getApprovalType (jenisSpk) {
      return getSpkApprovalType(jenisSpk)
    },

    onClickSigningAction (status) {
      this.modalSigning.status = status
      this.modalSigning.active = true
    }
  },
  beforeCreate () {
    this.$store.registerModule(['approvals', 'spkKontrakApproval'], moduleSpkKontrakApproval)
  },
  beforeDestroy () {
    this.$store.commit('approvals/spkKontrakApproval/RESET_STATE')
    this.$store.unregisterModule(['approvals', 'spkKontrakApproval'])
  }
}
</script>
